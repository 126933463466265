import React,{useState,useEffect} from 'react';
import axios from "axios";

const Home=()=>{
    const[productlist,updateproduct]=useState([]);
    const getproduct=()=>{
        axios.get(" http://localhost:1234/product")
        .then(response=>{
            if(response.data.length > 0){
            updateproduct(response.data);
            }
        })
    }

    useEffect(()=>{
        getproduct();

    },[1]);

    const[msg,updateMsg]=useState("");
    const addtocart=(productinfo)=>{
        var url="http://localhost:1234/cart";
        axios.post(url,productinfo)
        .then(response=>{
            updateMsg(productinfo.name+"added in your cart");
        })

    }
    return(
        <div>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h1 className='text-center text-primary'>Home shopping</h1>
                        <p className='text-danger text-center'>{msg}</p>
                    </div>
                    {
                        productlist.map((product,index)=>{
                            return(
                                  <div className="col-lg-3 mb-5" key={index}>
                                    <div className='p-3 shadow'>
                                        <h6>{product.name}</h6>
                                        <img src={product.photo} height="140" width="100%"/>
                                        <p>{product.details}</p>
                                        <p>Rs.{product.price}</p>
                                        <p className='text-center'>
                                            <button className="btn btn-danger btn-sm"
                                                onClick={addtocart.bind(this,product)}>
                                                <i className='fa fa-shopping-cart'></i>add to cart
                                            </button>
                                        </p>

                                    </div>
                                       
                                  </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default Home;