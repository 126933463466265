import React,{useState,useEffect} from 'react';
import axios from "axios";

const Cart=()=>{
    const[productlist,updateproduct]=useState([]);
    const getproduct=()=>{
        axios.get(" http://localhost:1234/cart")
        .then(response=>{
            if(response.data.length > 0){
            updateproduct(response.data);
            }
        })
    }

    const deletefromcart=(id)=>{
        var url="http://localhost:1234/cart/"+id;
        axios.delete(url)
        .then(response=>{
            getproduct();//to reload the cartlist after delete
        })

    }

    useEffect(()=>{
        getproduct();

    },[1]);

    const[fullname,pickname]=useState("");
    const[mobileno,pickmobile]=useState("");
    const[email,pickemail]=useState("");
    const[address,pickaddress]=useState("");

    const saveorder=()=>{
        var url="http://localhost:1234/order";
        var orderdata={"customername":fullname,
                        "mobile":mobileno,
                        "email":email,
                        "address":address,
                        "product":productlist
                        }
        axios.post(url,orderdata)
        .then(response=>{
            alert("your order placed successfully");
        })

    }

    return(
      
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-4'>
                         <div className="p-3 shadow rounded">
                         <h4>Customer Details</h4>
                         <div className='mb-3'>
                            <label>customer Name</label>
                            <input type="text" className="form-control"
                            onChange={obj=>pickname(obj.target.value)}/>
                         </div>

                         <div className='mb-3'>
                            <label>customer mobile no</label>
                            <input type="text" className="form-control"
                            onChange={obj=>pickmobile(obj.target.value)}/>
                         </div>

                         <div className='mb-3'>
                            <label>customer email-id</label>
                            <input type="text" className="form-control"
                            onChange={obj=>pickemail(obj.target.value)}/>
                         </div>

                         <div className='mb-3'>
                            <label>customer address</label>
                            <textarea className="form-control"
                            onChange={obj=>pickaddress(obj.target.value)}></textarea>
                         </div>

                         <button className="btn btn-primary" onClick={saveorder}>
                            place My order
                         </button>

                         </div>
                    </div>

                    <div className='col-lg-8'>
                        <h4 className='text-center'>{productlist.length}items in cart</h4>
                        <table className="table table-border shadow mt-3">
                            <thead>
                                <tr>
                                    <th>Item Name</th>
                                    <th>item price</th>
                                    <th>item quantity</th>
                                    <th>item action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    productlist.map((product,index)=>{
                                        return(
                                               <tr key={index}>
                                                  <td>{product.name}</td>
                                                  <td>{product.price}</td>
                                                  <td>1</td>
                                                  <td>
                                                    <i
                                                     className='fa fa-trash fa-2x text-danger'
                                                     onClick={deletefromcart.bind(this,product.id)}></i>
                                                  </td>
                                               </tr>
                                        )
                                    })
                                }
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
    
    )
}
export default Cart;