import React from 'react';
import Adminheader from "./adminheader";
import {useState,useEffect} from 'react';
import axios from 'axios';

const Adminproduct = () =>{
    const[allproduct, updateProduct] = useState([]);  

    const getProduct = () =>{
        axios.get("http://localhost:1234/product")
        .then(response=>{
            if(response.data.length>0){
                updateProduct(response.data);
            }
        })
    }

    useEffect(()=>{
        getProduct();
    },[1]);

    const[pname, pickName] = useState("");
    const[pprice, pickPrice] = useState("");
    const[pphoto, pickPhoto] = useState("");
    const[pdetails, pickDetails] = useState("");
    const[msg,updatemsg]=useState("");

    const save = () =>{
        var url = "http://localhost:1234/product";
        var pdata = {
            "name":pname,
            "price":pprice,
            "photo":pphoto,
            "details":pdetails
        }
        axios.post(url, pdata)
        .then(response=>{
            updatemsg(pname+"added successfully");
            getProduct();
            pickName("");
            pickPrice("");
            pickPhoto("");
            pickDetails("");


        })
    }

    const deletepro=(pid,name)=>{
        var url="http://localhost:1234/product/"+pid;
        axios.delete(url)
        .then(response=>{
            updatemsg(name+"deleted successfully");
            getProduct();
        })
    }
    return(
        <div>
            <Adminheader/>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-3">
                        <h4> Add Product </h4>
                        <div className="mb-3">
                            <label> Product Name </label>
                            <input type="text" className="form-control"
                            onChange={obj=>pickName(obj.target.value)} value={pname}/>
                        </div>
                        <div className="mb-3">
                            <label> Product Price </label>
                            <input type="text" className="form-control"
                            onChange={obj=>pickPrice(obj.target.value)} value={pprice}/>
                        </div>
                        <div className="mb-3">
                            <label> Product Photo </label>
                            <input type="text" className="form-control"
                            onChange={obj=>pickPhoto(obj.target.value)} value={pphoto}/>
                        </div>
                        <div className="mb-3">
                            <label> Product Details </label>
                            <textarea className="form-control" onChange={obj=>pickDetails(obj.target.value)} value={pdetails}> </textarea>
                        </div>
                        <div className="text-center">
                            <button onClick={save} className="btn btn-primary"> Save Product </button>
                        </div>
                    </div>
                    <div className="col-lg-9">
                            <h4 className="text-center"> {allproduct.length} - Available Products </h4>
                            <p className="text-center text-danger">{msg}</p>
                            <table className="table table-bordered mt-4 shadow text-center">
                                <thead>
                                    <tr className="bg-light text-primary ">
                                        <th> Id </th>
                                        <th> Name </th>
                                        <th> Price </th>
                                        <th> Details </th>
                                        <th> Photo </th>
                                        <th> Action </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        allproduct.map((pinfo, index)=>{
                                            return(
                                                <tr key={index}>
                                                <td> {pinfo.id} </td>   
                                                <td> {pinfo.name} </td>
                                                <td> {pinfo.price} </td>
                                                <td> {pinfo.details} </td>
                                                <td> 
                                                        <img height={50} width={50} src={pinfo.photo}/>
                                                    </td>
                                                    <td>
                                                        <i onClick={deletepro.bind(this,pinfo.id,pinfo.name)} className="fa fa-trash fa-lg text-danger"></i>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Adminproduct;

