import React,{useState} from 'react';
import axios from "axios";


const Login=()=>{
    const[username,pickname]=useState("");
    const[pass,pickpassword]=useState("");
    const[msg,updatemsg]=useState("enter login details");

    const gologin=()=>{
                if(username==""|| pass==""){
                    updatemsg("empty username or password");
                }else{
                     updatemsg("please wait processing");
                     axios.get("http://localhost:1234/account")
                     .then(response=>{
                        var semail = response.data[0].email;
                        var spass = response.data[0].password;
                        if(username==semail && pass==spass){
                            updatemsg("success:please wait redirecting....");
                            localStorage.setItem("adminid",response.data[0].id);
                            localStorage.setItem("name",response.data[0].name);
                            window.location.href="#/";//redirecting to main page
                            window.location.reload();
                            //after login admin dashboard will come 
                        }else{
                               updatemsg("fail:invalid or not exists !");
                        }

                     })
                }
    }
    
    return(
        <div>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-4 offset-4 p-3 border'>
                        <h1 className="text-center text-primary">Admin Login</h1>
                        <p>{msg}</p>
                        <div className="mb-3">
                            <label>e-mail id</label>
                            <input type="text" onChange={obj=>pickname(obj.target.value)} className='form-control'/>
                        </div>

                        <div className="mb-3">
                            <label>password</label>
                            <input type="password" onChange={obj=>pickpassword(obj.target.value)} className='form-control'/>
                        </div>

                        
                        <div className="text-center">
                            <button className="btn btn-primary" onClick={gologin}>login</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login;